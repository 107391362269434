import { CHATWOOT_TOKEN } from "@/const";
import { chatwootSlice } from "../slices/chatwootSlice";
import { AppDispatch, AppGetState } from "@/store/store";

const { setLoading, ready } = chatwootSlice.actions;

export const loadChatwootScript = (open: boolean) => (dispatch: AppDispatch, getState: AppGetState) => {
  const { initialized, isLoading } = getState().chatwoot;

  if (isLoading) {
    return console.log("Chatwoot > Throttled");
  }

  if (initialized) {
    window.$chatwoot.toggle("open");
    return console.log("Chatwoot > Already inited!");
  }

  console.log("Chatwoot > Load script");

  window.chatwootSettings = {
    position: "right",
    type: "expanded_bubble",
    launcherTitle: "Chat with us",
  };

  window.addEventListener("chatwoot:ready", function () {
    open && window["$chatwoot"].toggle("open");
    dispatch(ready());
  });

  // Inject script
  // https://chat.cloudsend.pro/app/accounts/2/settings/inboxes/14
  (function (d, t) {
    dispatch(setLoading(true));
    let BASE_URL = "https://chat.cloudsend.pro";
    let g = d.createElement(t) as HTMLScriptElement,
      s = d.getElementsByTagName(t)[0];
    g.src = BASE_URL + "/packs/js/sdk.js";
    g.defer = true;
    g.async = true;
    s.parentNode?.insertBefore(g, s);
    g.onload = function () {
      window.chatwootSDK.run({
        websiteToken: CHATWOOT_TOKEN,
        baseUrl: BASE_URL,
      });
    };
    localStorage.setItem("chatwoot", "auto");
  })(document, "script");
};
