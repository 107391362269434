"use client";
import { FreeChatCard } from "components/shared/cards/FreeChatCard";
import {
  WriterWithReviewCard,
  WriterWithReviewCardProps,
} from "components/shared/cards/WriterWithReviewCard";
import { useEffect, useState } from "react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { SliderPagination } from "./SliderPagination";
import styles from "./WritersSlider.module.scss";
import { useInteraction } from "@/helpers/useInteraction";

type WritersSliderProps = {
  writers: WriterWithReviewCardProps[];
};

const WritersSlider = ({ writers }: WritersSliderProps) => {
  const [swiper, setSwiper] = useState<SwiperCore>();

  const { isInteracted } = useInteraction();

  useEffect(() => {
    // SHIT SOLUTION FOR SHIT BROWSER
    swiper && setTimeout(() => swiper.update(), 100);
  }, [swiper]);

  return (
    <>
      <div className={styles.sliderInner}>
        {!isInteracted && (
          <div className={styles.mocked}>
            {writers.slice(0, 2).map((el, idx) => (
              <div key={idx}>
                <WriterWithReviewCard {...el} className={styles.card} />
              </div>
            ))}
            <div key="freechatcard">
              <FreeChatCard className={styles.freeChatCard} />
            </div>
            {writers.slice(2, writers.length).map((el, idx) => (
              <div key={idx}>
                <WriterWithReviewCard {...el} className={styles.card} />
              </div>
            ))}
          </div>
        )}

        {isInteracted && (
          <Swiper
            spaceBetween={15}
            slidesPerView={1}
            loopAdditionalSlides={4}
            modules={[Pagination]}
            loop={true}
            onSwiper={setSwiper}
            pagination={{
              el: ".writers-slider-pagination",
              clickable: true,
            }}
            className={styles.slider}
            breakpoints={{
              576: {
                spaceBetween: 30,
              },
            }}
          >
            {writers.slice(0, 2).map((el, idx) => (
              <SwiperSlide key={idx}>
                <WriterWithReviewCard {...el} className={styles.card} />
              </SwiperSlide>
            ))}
            <SwiperSlide key="freechatcard">
              <FreeChatCard className={styles.freeChatCard} />
            </SwiperSlide>
            {writers.slice(2, writers.length).map((el, idx) => (
              <SwiperSlide key={idx}>
                <WriterWithReviewCard {...el} className={styles.card} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      <SliderPagination
        onNextClick={() => {
          swiper?.slideNext();
        }}
        onPrevClick={() => {
          swiper?.slidePrev();
        }}
        paginationClassName="writers-slider-pagination"
      />
    </>
  );
};

export default WritersSlider;
