import cn from "classnames";
import { ChatwootBtn } from "components/scripts/chatwoot/ChatwootBtn";
import { Button } from "components/UI/Buttons/Button";
import styles from "./FreeChatCard.module.scss";
import Image from "next/image";

type FreeChatCardProps = {
  className?: string;
};

export const FreeChatCard = ({ className }: FreeChatCardProps) => {
  return (
    <div className={cn(styles.card, className)}>
      <div className={styles.center}>
        <div className={styles.img}>
          <Image width={70} height={70} src="/img/wrapped-gift.png" alt="Free chat" />
        </div>
        <p className={styles.text}>
          Reach the writer to clarify <br />
          the details.
        </p>
        <div className={styles.btnWrap}>
          <Button className="swiper-no-swiping" type="accent" size="fullwidth">
            Hire Writer
            <ChatwootBtn />
          </Button>
        </div>
      </div>
    </div>
  );
};
