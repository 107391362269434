"use client";

import Link from "next/link";
import dynamic from "next/dynamic";

type SimpleContentParserProps = {
  content?: string;
  className?: string;
};

const JsxParser = dynamic(() => import("react-jsx-parser"));

export const SimpleContentParser = ({ content, className }: SimpleContentParserProps) => {
  if (!content) return null;
  const wrappedContent = /^<.+>/.test(content.trim()) ? content : "<p>" + content + "</p>";
  return (
    <JsxParser
      components={{
        Link: Link as React.ComponentType<any>,
      }}
      autoCloseVoidElements
      className={className}
      jsx={wrappedContent}
    />
  );
};

export default SimpleContentParser;
