'use client';
import { useDispatch } from 'react-redux';
import { loadChatwootScript } from '@/store/actions/chatwootActions';

export const ChatwootBtn = () => {
  const dispatch = useDispatch();

  const initChatwoot = () => {
    dispatch(loadChatwootScript(true));
  };

  return (
    <span
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1000,
      }}
      onClick={initChatwoot}
    />
  );
};
