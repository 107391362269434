import cn from "classnames";
import { WrappedIcon } from "components/UI/WrappedIcon";
import { upperFirst } from "lodash";
import s from "./SliderPagination.module.scss";
import IconArrowRight from "public/img/icons/svg/angle-right-b.svg";
import IconArrowLeft from "public/img/icons/svg/angle-left-b.svg";

type SliderPaginationProps = {
  color?: "default" | "white";
  className?: string;
  paginationClassName?: string;
  onNextClick: () => void;
  onPrevClick: () => void;
};

export const SliderPagination = ({
  color = "default",
  className,
  paginationClassName,
  onNextClick,
  onPrevClick,
}: SliderPaginationProps) => (
  <div className={cn(s.wrapper, s[`color${upperFirst(color)}`], className)}>
    <WrappedIcon
      icon={IconArrowLeft}
      type="white"
      onClick={onPrevClick}
      className={cn(s.button, s.buttonLeft)}
    />
    <div className={cn(s.pagination, paginationClassName)} />
    <WrappedIcon
      icon={IconArrowRight}
      type="white"
      onClick={onNextClick}
      className={cn(s.button, s.buttonRight)}
    />
  </div>
);
