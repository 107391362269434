import cn from "classnames";
import { Rating } from "components/shared/review/Rating";
import s from "./WriterWithReviewCard.module.scss";
import { WrappedIcon } from "components/UI/WrappedIcon";
import { Button } from "components/UI/Buttons/Button";
import { SimpleContentParser } from "../SimpleContentParser";
import { orderLink } from "@/const";
import Image from "next/image";
import { IconImage } from "@/components/UI/IconImage";
import { upperFirst } from "lodash";

type WriterInfo = {
  img: string;
  name: string;
  rating: number;
  reviews: number;
  finishedOrders: number;
  successRate: number;
  competence: string;
};

type ReviewInfo = {
  id: number | string;
  date: string;
  text: string;
};

export type WriterWithReviewCardProps = {
  className?: string;
  writer: WriterInfo;
  review: ReviewInfo | null;
  variant?: "default" | "writersPage";
  slug?: string;
};

export const WriterWithReviewCard = ({
  writer,
  review,
  variant,
  className,
  slug,
}: WriterWithReviewCardProps) => {
  return (
    <div className={cn(s.card, s[`variant${upperFirst(variant)}`], className)}>
      <div className={s.writer}>
        <div className={s.writerTop}>
          <div className={s.writerImg}>
            <Image src={writer.img} alt={writer.name} quality={100} width={64} height={64} />
          </div>
          <div className={s.writerHeading}>
            <strong className={s.writerName}>{writer.name}</strong>
            <Rating rating={writer.rating} className={s.writerRating} />
            <div className={s.writerReviewsNum}>({writer.reviews} reviews)</div>
          </div>
        </div>
        <ul className={s.writerFeatures}>
          <li className={s.writerFeature}>
            <IconImage size={30} icon="file" />
            <div className={s.writerFeatureContent}>
              <div className={s.writerFeatureHeading}>Finished orders:</div>
              <div className={s.writerFeatureText}>{writer.finishedOrders}</div>
            </div>
          </li>
          <li className={s.writerFeature}>
            <IconImage size={30} icon="medal" />
            <div className={s.writerFeatureContent}>
              <div className={s.writerFeatureHeading}>Success rate:</div>
              <div className={s.writerFeatureText}>{writer.successRate}%</div>
            </div>
          </li>
          {!!writer.competence && (
            <li className={s.writerFeature}>
              <IconImage size={30} icon="pen" />
              <div className={s.writerFeatureContent}>
                <div className={s.writerFeatureHeading}>Competence:</div>
                <div className={s.writerCompenteces}>
                  {writer.competence.split(",").map((text, index) => (
                    <Button
                      key={index}
                      type="white"
                      size="xs"
                      outline
                      span
                      noHover
                      className={s.writerCompetence}
                    >
                      {text.trim()}
                    </Button>
                  ))}
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
      {review && (
        <div className={s.review}>
          <div className={s.reviewMain}>
            <strong className={s.reviewTitle}>
              Recent review about {variant === "default" && <br />}
              this writer
            </strong>
            <div className={s.reviewTop}>
              <IconImage size={30} icon="graduation-cap" />
              <div className={s.reviewHeading}>
                <div className={s.reviewName}>Customer ID #{review.id}</div>
                <div className={s.reviewDate}>{review.date}</div>
              </div>
            </div>
            <SimpleContentParser content={review.text} className={s.reviewText} />
          </div>

          {slug ? (
            <div className={s.btns}>
              <Button className="swiper-no-swiping" href={`/writers/${slug}`} type="white" outline>
                View profile
              </Button>
              <Button className="swiper-no-swiping" href={orderLink}>
                Hire
              </Button>
            </div>
          ) : (
            <Button className="swiper-no-swiping" href={orderLink} type="primary" outline>
              Hire
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
