import cn from "classnames";
import Image from "next/image";
import styles from "./Rating.module.scss";

type RatingProps = {
  rating: number;
  showValue?: boolean;
  className?: string;
};

export const Rating = ({ rating, showValue = true, className }: RatingProps) => {
  return (
    <div className={cn(styles.rating, className)}>
      <div className={styles.stars}>
        <Image
          width={68}
          height={12}
          alt={`Rating ${rating}`}
          src={rating > 4.6 ? "/img/misc/reviews/stars-full.svg" : "/img/misc/reviews/stars.svg"}
          suppressHydrationWarning
        />
      </div>
      {showValue && <div className={styles.number}>{rating}</div>}
    </div>
  );
};
